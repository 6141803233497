let userKey = '';
const STORAGE_KEY = 'ca-storage-userKey';
const getUserKeyFromStorage = () => {
  try {
    const userKeyFromStorage = localStorage.getItem(STORAGE_KEY);

    return userKeyFromStorage;
  } catch (e) {
    return '';
  }
};

const setUserKeyToStorage = (key: string) => {
  try {
    localStorage.setItem(STORAGE_KEY, key);
  } catch (e) {
    // do nothing
  }
};

const initUserKey = () => {
  const key = getUserKeyFromStorage();

  if (!key) {
    userKey = Math.random().toString(36).substring(2);
    setUserKeyToStorage(userKey);
  } else {
    userKey = key;
  }
};

export const getUserKey = () => {
  if (!userKey) {
    initUserKey();
  }

  return userKey;
};
