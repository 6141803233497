import {
  MerchantApplyOpt,
  MerchantCheckoutOpt,
  MerchantEligibilityOpt,
  MerchantIdentificationCollectionOpt,
  CallbackStatus,
} from '@chargeafter/payment-types';
import { MerchantOpt } from '@chargeafter/types-sdk';

import { identificationCollectionPresent } from './identification/identificationPresent';
import { paymentsPresent } from './payments/paymentsPresent';
import { promotionPresent } from './promotions/promotionsPresent';

import { logger } from '../../logger';
import { modalPresentEligibility } from '../../manager';
import { validateCartDetailsFully } from '../../validations/validations';
import { printInitError } from '../printInitError/printInitError';
import { store } from '../store';

const filename = 'present';

export const present = async (
  type:
    | 'apply'
    | 'checkout'
    | 'promotion'
    | 'eligibility'
    | 'identification-collection',
  opt:
    | MerchantApplyOpt
    | MerchantCheckoutOpt
    | string
    | MerchantEligibilityOpt
    | MerchantIdentificationCollectionOpt
) => {
  if (!store.isInitialized) return printInitError('present', filename);

  logger.linkId = store.config?.linkId;

  if (typeof opt === 'object' && 'correlationId' in opt && opt.correlationId) {
    logger.correlationId = opt.correlationId;
  }

  logger.log(`about to present ${type}`, {
    function: 'present',
    filename,
    opt,
  });

  switch (type) {
    case 'apply':
    case 'checkout':
      return paymentsPresent(type, opt as MerchantOpt);

    case 'promotion':
      await store.sdkInitializedPromise;

      return promotionPresent(opt as string);

    case 'eligibility': {
      const { lenderId, container, callback, cartDetails, preferences } =
        opt as MerchantEligibilityOpt;

      const callbackMiddleware: MerchantCheckoutOpt['callback'] = (
        token,
        data,
        status
      ) => {
        callback?.(undefined, status);
      };

      if (!validateCartDetailsFully(cartDetails, callbackMiddleware)) {
        return false;
      }

      return modalPresentEligibility({
        lenderId,
        container,
        cartDetails,
        callback,
        config: store.config,
        preferences,
      });
    }

    case 'identification-collection':
      return identificationCollectionPresent(
        opt as MerchantIdentificationCollectionOpt
      );

    default: {
      const status: CallbackStatus = {
        code: 'CREATE_CHECKOUT_FAILED',
        message: `${type} type is not support`,
      };

      if ((opt as MerchantCheckoutOpt)?.callback) {
        (opt as MerchantCheckoutOpt)?.callback?.(undefined, undefined, status);
      }

      logger.error(`${type} type is not support`, {
        filename,
        function: 'present',
      });

      return { token: undefined, data: undefined, status };
    }
  }
};
