import { Config } from '@chargeafter/payment-types';

import { logger } from '../logger';

export const serialize = (obj: Record<string, unknown> | Config) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    logger.error('failed to serialize object', {
      function: 'serialize',
      filename: 'serialize',
      obj,
      e,
    });
    return obj;
  }
};
