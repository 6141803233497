import { logger } from '../../logger';

export const printInitError = (functionName: string, filename: string) => {
  logger.error(`called ${functionName} without initializing sdk`, {
    function: functionName,
    filename,
    printOriginalToConsole: true,
    isSeverityWarn: true,
  });
};
