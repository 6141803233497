import { LogData, LogLevel, LogsType } from './logger.interface';

// eslint-disable-next-line no-restricted-globals
const refWindow = window;

export const refConsole: Console = refWindow.console;

const FILTER_WARNING_ERRORS_REACT = [
  '^Warning: Received .* for a non-boolean attribute .*',
  '^Warning: React does not recognize the .* prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase .* instead. If you accidentally passed it from a parent component, remove it from the DOM element.*',
  '^Using kebab-case for css properties in objects is not supported. Did you mean.*',
  '^Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component. More info: https://reactjs.org/link/controlled-components',
  '^Warning: findDOMNode is deprecated and will be removed in the next major release. Instead, add a ref directly to the element you want to reference. Learn more about using refs safely.*',
  '^Warning: validateDOMNesting\\(\\.\\.\\.\\): .* cannot appear as a descendant of .*',
  '^Warning: Failed .* type: .*',
].join('|');

export const serviceWrapper =
  (
    getServiceData: () => {
      version: string;
      service: string;
    },
    cb: (message: string, args: LogData) => void
  ) =>
  (message: string, args: LogData) => {
    cb(message, { ...args, ...getServiceData() });
  };

const consoleOverrideApps = ['modal', 'salesapp'];

const interceptConsole = (type: LogLevel, args: unknown[]) => {
  const logType = LogsType[type];

  if (type !== LogLevel.ERROR) {
    const messages = args.filter((val) => typeof val === 'string');

    const printArgs =
      (args.length > 1 &&
        (typeof args[0] === 'string' ? args.slice(1, args.length) : args)) ||
      [];

    refConsole[logType](
      ...messages,
      {
        function: 'interceptConsole',
        filename: 'LoggerService',
      },
      ...printArgs
    );
  }

  if (type === LogLevel.ERROR) {
    if (
      typeof args?.[0] === 'string' &&
      new RegExp(FILTER_WARNING_ERRORS_REACT).test(args[0] as string)
    ) {
      refConsole.warn(...args);
    } else {
      refConsole[logType](...args);
    }
  }
};

export const startInterceptingConsole = (appName: string) => {
  if (consoleOverrideApps.indexOf(appName.toLowerCase()) !== -1) {
    refWindow.console = {
      ...refWindow.console,
      log: (...args: unknown[]) => interceptConsole(LogLevel.LOG, args),
      info: (...args: unknown[]) => interceptConsole(LogLevel.INFO, args),
      warn: (...args: unknown[]) => interceptConsole(LogLevel.WARN, args),
      error: (...args: unknown[]) => interceptConsole(LogLevel.ERROR, args),
    };
  }
};
