import { Config } from '@chargeafter/payment-types';
import { uuidv4 } from '@chargeafter/utils';

let localBrowserSessionId: string;
let browserSessionIdTimestamp: number;
let sendSessionStartedEvent = true;
// eslint-disable-next-line no-restricted-globals
const refWindow = window;

type HiddenBrowserSessionId = Config & { browserSessionId?: string };

export const browserSessionId = {
  get get() {
    const configBrowserSessionId =
      (refWindow.caConfig as HiddenBrowserSessionId)?.browserSessionId ||
      (refWindow.ChargeAfter?.cfg as HiddenBrowserSessionId)?.browserSessionId;

    if (!localBrowserSessionId) {
      localBrowserSessionId = configBrowserSessionId || uuidv4();

      browserSessionIdTimestamp = Date.now();
      sendSessionStartedEvent = true;
    }

    return localBrowserSessionId;
  },

  get sendBrowserSessionStartedEvent() {
    return sendSessionStartedEvent;
  },

  set sendBrowserSessionStartedEvent(value: boolean) {
    sendSessionStartedEvent = value;
  },

  get timestamp() {
    if (!localBrowserSessionId) return 0;

    return browserSessionIdTimestamp;
  },

  set set(value: string) {
    if (value !== localBrowserSessionId) {
      sendSessionStartedEvent = true;
      browserSessionIdTimestamp = Date.now();
      localBrowserSessionId = value;
    }
  },
};
