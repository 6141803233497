import { SDKConfig } from '@chargeafter/types-sdk';

import { dispose } from '../dispose/dispose';
import { store } from '../store';
import { update } from '../update/update';

// FIXME: create new message event to update config instead
export const updateConfig = async (merchantConfig: Partial<SDKConfig>) => {
  store.config = { ...store.config, ...merchantConfig };
  dispose();
  await update();
};
