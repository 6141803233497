import { Promotion } from '@chargeafter/types-session';

import { logger } from '../../logger';
import { modalGetDirectLenders } from '../../manager';
import { store } from '../store';

const filename = 'getDirectLenders.ts';

export const getDirectLenders = async () => {
  logger.log('getting direct lenders for merchant', {
    filename,
    function: 'getDirectLenders',
  });
  const lenders = await modalGetDirectLenders(
    store.config,
    store.basePromotionModalData?.configurationSessionId
  );

  for (const lender of lenders) {
    store.widgets[lender.id] = {
      promotion: {
        lender: {
          id: lender.id,
          name: lender.name,
        },
      } as Promotion,
    };
  }

  return lenders;
};
