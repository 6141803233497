import {
  ChargeAfter as ChargeAfterType,
  Config,
} from '@chargeafter/payment-types';

import { SDK } from './app';

// eslint-disable-next-line no-restricted-globals
const refWindow = window;

const sdk = SDK;

let fraudDetection: ChargeAfterType['fraudDetection'];

if (refWindow.ChargeAfter) {
  if (refWindow.ChargeAfter?.dispose) refWindow.ChargeAfter?.dispose();

  fraudDetection = refWindow.ChargeAfter?.fraudDetection;
}

const config = (refWindow.caConfig ||
  (refWindow.ChargeAfter as unknown as Record<string, unknown>)?.cfg) as Config;

sdk.fraudDetection = fraudDetection;
refWindow.ChargeAfter = sdk;
config && sdk.init(config);

export const ChargeAfter: typeof SDK = sdk;
