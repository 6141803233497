import { PerformAdditionalActionContext } from '@chargeafter/payment-types';

import { logger } from '../../logger';
import { paymentsPresent } from '../present/payments/paymentsPresent';
import { printInitError } from '../printInitError/printInitError';
import { store } from '../store';

const filename = 'performAdditionalAction';

export const performAdditionalAction = async (
  sessionToken: string,
  context?: PerformAdditionalActionContext
) => {
  if (!store.isInitialized)
    return printInitError('performAdditionalAction', filename);

  logger.linkId = store.config?.linkId;

  logger.log(`about to present PerformAdditionalAction`, {
    function: 'performAdditionalAction',
    filename,
  });

  return paymentsPresent('performAdditionalAction', {
    ...context,
    sessionToken,
  });
};
