import { ExternalUrlData } from '@chargeafter/types-lender';
import { uuidv4 } from '@chargeafter/utils';

import { logger } from '../logger';

export class DBUY {
  isEventAdded = false;
  modal: HTMLIFrameElement;
  // eslint-disable-next-line no-restricted-globals
  windowRef = window;

  constructor() {
    this.load = this.load.bind(this);
  }

  load(dbuyData: ExternalUrlData, modal: HTMLIFrameElement) {
    const { url, dbuy = {} } = dbuyData;

    logger.log('DBUY_START_LOADING', {
      filename: 'dbuy.ts',
      function: 'load',
    });
    this.modal = modal;

    const onLoadScript = () => {
      logger.log('DBUY_SCRIPT_LOADEDG', {
        filename: 'dbuy.ts',
        function: 'onLoadScript',
      });

      if (!this.isEventAdded) {
        this.windowRef.addEventListener('message', this.modalCloseHandler);
        this.isEventAdded = true;
      }

      this.sendMsg({
        action: 'DBUY_RETURNED_MSG',
        dbuyData: {
          action: 'DBUY_LOADED',
        },
      });

      this.windowRef.syfDBuy?.calldBuyProcess(null, {
        ...dbuy,
        processInd: 1,
        clientTransId: uuidv4(),
      });
      setTimeout(() => {
        modal.style.display = 'none';
      }, 50);
    };

    const onErrorScript = (
      event: string | Event,
      source?: string,
      lineno?: number,
      colno?: number,
      error?: Error
    ) => {
      logger.log('DBUY_FAIL_O_LOAD', {
        filename: 'dbuy.ts',
        function: 'onErrorScript',
        error,
        event,
        source,
        lineno,
        colno,
        errorMessage: error.message,
      });

      modal.style.display = 'block';
      this.sendMsg({
        action: 'DBUY_RETURNED_MSG',
        dbuyData: {
          action: 'DBUY_ERROR',
        },
      });
    };

    if (document.getElementById('caSyfDbuyScript')) {
      return onLoadScript && setTimeout(onLoadScript, 50);
    }

    if (url) {
      const syfScript = document.createElement('script');

      syfScript.id = 'caSyfDbuyScript';
      syfScript.onload = onLoadScript;
      syfScript.onerror = onErrorScript;
      syfScript.src = url;
      document.body.appendChild(syfScript);
    }

    return undefined;
  }

  private modalCloseHandler = (event: MessageEvent) => {
    let isRemoveListener = false;

    if (typeof event.data === 'string') {
      isRemoveListener = false;

      switch (event.data) {
        case 'Close Model':
        case 'Return To Merchant Shipping':
          isRemoveListener = true;
          logger.log('DBUY_RETURNED_MSG', {
            filename: 'dbuy.ts',
            function: 'modalCloseHandler',
            data: event.data,
          });

          this.sendMsg({
            action: 'DBUY_RETURNED_MSG',
            dbuyData: {
              action: 'DBUY_CLOSE',
            },
          });
          this.modal.style.display = 'block';

          break;

        default:
          break;
      }
    }

    if (isRemoveListener) {
      logger.log('DBUY_REMOVING', {
        filename: 'dbuy.ts',
        function: 'modalCloseHandler',
        data: event.data,
      });

      this.windowRef.removeEventListener('message', this.modalCloseHandler);
      this.isEventAdded = false;
      this.modal = null;
      this.windowRef = null;
    }
  };

  sendMsg = (content: unknown) => {
    if (this.modal) {
      this.modal.contentWindow.postMessage(content, '*');
    }
  };
}
