import { logger } from '../../logger';
import { refWindow } from '../../utils/windowRef';
import { store } from '../store';

const filename = 'openLink';

export const openLink = (sku: string) => {
  logger.log(`learn more pressed`, {
    function: 'openLink',
    filename,
    sku,
    widgetData: store.widgets?.[sku],
  });

  if (sku && store.widgets?.[sku]?.widget?.widgetFinancingPageUrl) {
    const widgetData = store.widgets[sku];

    refWindow.open(widgetData.widget?.widgetFinancingPageUrl, '_blank');
  }
};
