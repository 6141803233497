import EventEmitter from 'events';

import { ConsumerDetails } from '@chargeafter/payment-types';
import { CompleteOptions } from '@chargeafter/types-checkout';
import {
  MessageOpenExternalUrlPayload,
  SDKMessagePayload,
} from '@chargeafter/types-sdk';

import { logger } from '../../logger';
import { ModalData } from '../models/manager.model';

// eslint-disable-next-line no-restricted-globals
const refWindow = window;
const filename = 'external.actions';
const refDocument = document;

export const loadExternalScript = (
  modalData: ModalData,
  {
    scriptUrl,
    scriptInfo: { headersInfo, ...config },
  }: Omit<MessageOpenExternalUrlPayload, 'action'>,
  {
    createScript,
    sendMsg,
  }: {
    createScript: (url: string, onLoadFailed: () => void) => HTMLScriptElement;
    sendMsg: (
      payload: Exclude<SDKMessagePayload, 'source'>,
      target?: HTMLIFrameElement
    ) => void;
  }
) => {
  let scriptEl: HTMLScriptElement = undefined;
  const emitter = new EventEmitter();
  const modal = modalData.iframe;
  const id = modalData.id;

  const clean = () => {
    scriptEl?.remove();
    refWindow.caExternalScript = undefined;
    emitter.removeAllListeners();
    modal.style.display = 'block';
  };

  const complete = (
    success: boolean,
    consumerDetails?: ConsumerDetails,
    options?: CompleteOptions
  ) => {
    clean();
    sendMsg(
      {
        type: 'payments',
        action: 'OPEN_EXTERNAL_SCRIPT_COMPLETE',
        paymentsData: { success, consumerDetails, options },
        id,
      },
      modal
    );
  };

  const onLoadCompleted = () => {
    modal.style.display = 'none';
    sendMsg(
      {
        type: 'payments',
        action: 'OPEN_EXTERNAL_SCRIPT_LOADED',
        id,
      },
      modal
    );
  };

  const onFailed = () => {
    clean();
    logger.error(`External script failed to load`, {
      function: 'loadExternalScript',
      filename,
    });

    sendMsg(
      {
        type: 'payments',
        action: 'OPEN_EXTERNAL_SCRIPT_COMPLETE',
        paymentsData: { success: false },
        id,
      },
      modal
    );
  };

  const loggerService = logger.service(
    modalData.data?.lenderName ||
      modalData.data?.lenderId ||
      modalData.data?.lenderSessionId ||
      ''
  );

  emitter.addListener('complete', complete);
  emitter.addListener('onLoadComplete', onLoadCompleted);

  refWindow.caExternalScript = Object.freeze<typeof refWindow.caExternalScript>(
    {
      complete: (...args) => emitter.emit('complete', ...args),
      onLoadCompleted: () => emitter.emit('onLoadComplete'),
      config: Object.freeze(config),
      headersInfo: Object.freeze(headersInfo),
      logger: loggerService,
    }
  );

  scriptEl = createScript(scriptUrl, onFailed);
  refDocument.body.appendChild(scriptEl);
};
