import { LogData, FormatData, Log } from './logger.interface';

const consoleColors = ['color: #d96666', 'color: #6495ed'];
const baseFilter = ['appName', 'service', 'message'];
const serverFilter: string[] = [];
const consoleFilter = [
  'printOriginalToConsole',
  'isConsoleOnly',
  'filename',
  'function',
];

export const filterProps = (isConsoleLog: boolean, obj: LogData) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      (isConsoleLog ? consoleFilter : serverFilter).includes(key) ||
      baseFilter.includes(key)
    ) {
      return acc;
    }

    acc[key] = value;
    return acc;
  }, {} as LogData);
};

const formatErrorForServer = (data: LogData) => {
  const { error, err, er, e } = data;
  const checkError = error || err || er || e;
  const errorFieldName = error ? 'error' : err ? 'err' : er ? 'er' : 'e';

  if (checkError && checkError instanceof Error) {
    const { name, message, stack } = checkError;

    data[errorFieldName] = {
      name,
      message,
      stack,
    };
  }
};

const formatServerLog = ({
  module = '',
  message,
  logData,
}: FormatData): [string, LogData] => {
  const newLogData = filterProps(false, logData);

  formatErrorForServer(newLogData);
  return [
    `[Client]${module.replace(/%c| /g, '')}${(message && ` ${message}`) || ''}`,
    newLogData,
  ];
};

const formatConsoleLog = (
  { message, logMessage, logData }: FormatData,
  consoleColors: string[]
): unknown[] => {
  const newLogData: Partial<LogData> = filterProps(true, logData);

  return [
    logMessage,
    ...consoleColors,
    message + (Object.keys(newLogData).length ? ',' : ''),
    ...Object.entries(newLogData).reduce((acc, [key, value]) => {
      acc.push(`${key}:`, typeof value === 'string' ? `${value},` : value);
      return acc;
    }, [] as unknown[]),
  ];
};

type LogDataWithMessage = LogData & { message: string };

export const format = (log: LogDataWithMessage): Log => {
  const message = log.message ?? '';

  const module = `%c[${log.appName}]${
    (log.service && ` [${log.service}]`) || ''
  }`;
  const callerName = ` %c[${log.filename}] [${log.function}]`;
  const logMessage = `${module}${callerName}`;

  const formattedMessage = {
    module,
    logMessage,
    message,
    logData: log,
  };

  return {
    server:
      (!log.isConsoleOnly && formatServerLog(formattedMessage)) || undefined,
    console: formatConsoleLog(formattedMessage, consoleColors),
  };
};
