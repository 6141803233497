import { MessageIdentificationCollectionOpenModalPayload } from '@chargeafter/types-sdk';

import { modalPresent } from '../../../manager';
import { OpenIdentificationCollectionModal } from '../../../manager/modal-manager/manager.model';
import { store } from '../../store';

export const identificationCollectionPresent = async (
  opt: MessageIdentificationCollectionOpenModalPayload['payload']
) => {
  const payload: OpenIdentificationCollectionModal = {
    type: 'identification-collection',
    payload: opt,
  };

  const data = await modalPresent({
    payload,
    config: store.config,
    isCheckout: false,
  });

  if (data.status) {
    throw data.status;
  }

  return data.token;
};
