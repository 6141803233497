import { logger } from '../../../logger';
import { modalPresent } from '../../../manager';
import { OpenPromotionsModal } from '../../../manager/modal-manager/manager.model';
import { store } from '../../store';

const filename = 'promotionsPresent';

export const promotionPresent = (arg: string) => {
  const ttlStartTime = Date.now();
  const [sku, selectedLenderId] = arg.split('-lenderId-');

  const promotion = store.widgets[sku]?.promotion;

  if (!promotion)
    return logger.error(
      `SKU: ${sku} is not exist, are you sure this is the correct SKU?`,
      {
        function: 'promotionPresent',
        filename,
        printOriginalToConsole: true,
      }
    );

  const payload: OpenPromotionsModal = {
    selectedLenderId,
    type: 'promotions',
    widget: { widgetData: store.widgets[sku].widget },
    configurationSessionId:
      store.basePromotionModalData?.configurationSessionId,
    ttlStartTime,
    promotionData: {
      ...store.basePromotionModalData,
      promotion,
    },
  };

  return modalPresent({
    payload,
    config: store.config,
    isCheckout: false,
    merchantActions: {
      onComplete: store.config.onComplete,
    },
  });
};
