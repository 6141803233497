// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import Resolvable from 'resolvable-promise';

export const asyncDebounce = <
  Args extends any[],
  F extends (...props: Args) => any = any,
  ReturnData = ReturnType<F>
>(
  func: F,
  wait = 0
) => {
  let timeout: number;
  let promise: Resolvable<ReturnData> | null = null;

  return (async (...args: Args) => {
    const later = async () => {
      clearTimeout(timeout);
      const response = await func(...args).catch((error: Error) => {
        promise?.reject(error);
        promise = null;
        throw error;
      });

      promise?.resolve(response);
      promise = null;
    };

    if (!promise) {
      promise = new Resolvable<ReturnData>();
    }

    clearTimeout(timeout);
    timeout = setTimeout(later, wait) as unknown as number;

    return promise;
  }) as F;
};
