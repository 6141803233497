import { ModalMessagePayload, SDKMessagePayload } from '@chargeafter/types-sdk';

import { environment } from '../../../environments/environment';
import { logger } from '../../logger';
import { NarrowAction } from '../models/manager.model';

// eslint-disable-next-line no-restricted-globals
const refWindow = window;
const filename = 'message.listener';
let listeners: {
  [k: string]: {
    listener?: (event: MessageEvent<ModalMessagePayload>) => unknown;
    signal?: AbortController;
  };
} = {};

export const createMessageFunction = <
  Action extends SDKMessagePayload['action'],
  ResponseAction extends ModalMessagePayload['action'],
  T extends Omit<
    NarrowAction<SDKMessagePayload, Action>,
    'action' | 'source'
  > = Omit<NarrowAction<SDKMessagePayload, Action>, 'action' | 'source'>,
  S extends NarrowAction<ModalMessagePayload, ResponseAction> = NarrowAction<
    ModalMessagePayload,
    ResponseAction
  >
>(
  action: Action,
  responseAction: ResponseAction | ResponseAction[]
): ((payload: T, target?: HTMLIFrameElement) => Promise<S>) => {
  return (payload: T, target?: HTMLIFrameElement): Promise<S> => {
    logger.log(`creating message listeners for ${target?.name}`, {
      function: 'createMessageFunction',
      filename,
    });

    const responsesActions =
      typeof responseAction === 'string' ? [responseAction] : responseAction;

    return new Promise<S>((resolve) => {
      const controller = new AbortController();
      const listener = (message: MessageEvent<S>) => {
        const { data } = message;

        if (
          data.source === 'CAMODAL' &&
          responsesActions.includes(data.action) &&
          (data.id === target?.name ||
            (data.id === 'static' && target?.name === 'global'))
        ) {
          logger.log(`received message ${data.action} for ${target?.name}`, {
            function: 'createMessageFunction',
            filename,
            data,
          });
          resolve(data as unknown as S);
          controller.abort();
          refWindow.removeEventListener('message', listener);
        }
      };

      refWindow.addEventListener('message', listener, {
        signal: controller.signal,
      });

      target?.contentWindow?.postMessage(
        { ...payload, source: 'CASDK', id: target.name, action },
        '*'
      );
    });
  };
};

export const sendMessageToModal = async <
  R extends ModalMessagePayload['action'],
  T extends NarrowAction<ModalMessagePayload, R> = NarrowAction<
    ModalMessagePayload,
    R
  >
>(
  payload: Exclude<SDKMessagePayload, 'source'>,
  responseMessageType?: R | R[],
  target?: HTMLIFrameElement
) => {
  return new Promise<T | void>((resolve) => {
    logger.log(`sending message ${payload.action} to ${target?.name}`, {
      function: 'sendMessageToModal',
      filename,
      payload,
      responseMessageType,
    });
    const controller = new AbortController();
    const listener = (message: MessageEvent<ModalMessagePayload>) => {
      const { data } = message;

      if (
        data.source === 'CAMODAL' &&
        data.action === responseMessageType &&
        data.id === target.name
      ) {
        logger.log(`received message ${data.action} for ${target?.name}`, {
          function: 'sendMessageToModal',
          filename,
          data,
        });
        resolve(data as unknown as T);
        controller.abort();
        refWindow.removeEventListener('message', listener);
      }
    };

    if (responseMessageType) {
      refWindow.addEventListener('message', listener, {
        signal: controller.signal,
      });
    } else {
      resolve();
    }

    target?.contentWindow?.postMessage(
      { ...payload, source: 'CASDK', id: target.name },
      '*'
    );
  });
};

type Actions<
  Action extends ModalMessagePayload['action'] = ModalMessagePayload['action'],
  ResponseAction extends SDKMessagePayload['action'] = SDKMessagePayload['action'],
  A extends NarrowAction<ModalMessagePayload, Action> = NarrowAction<
    ModalMessagePayload,
    Action
  >,
  R extends Omit<
    NarrowAction<SDKMessagePayload, ResponseAction>,
    'action' | 'source' | 'id'
  > = Omit<
    NarrowAction<SDKMessagePayload, ResponseAction>,
    'action' | 'source' | 'id'
  >
> = {
  [k in Action]?: {
    responseAction?: ResponseAction;
    onMessage: (data: A) => Promise<R | void>;
  };
};

export const createListenersEvents = <
  Action extends ModalMessagePayload['action'] = ModalMessagePayload['action'],
  ResponseAction extends SDKMessagePayload['action'] = SDKMessagePayload['action']
>(
  modalId: string,
  target: HTMLIFrameElement,
  actions: Actions<Action, ResponseAction>
) => {
  listeners[modalId] = {};

  listeners[modalId].listener = async ({
    data,
  }: MessageEvent<NarrowAction<ModalMessagePayload, Action>>) => {
    if (data.source === 'CAMODAL') {
      if (actions[data.action] && data.id === target.name) {
        logger.log(`received message ${data.action} for ${target?.name}`, {
          function: 'createListenersEvents',
          filename,
          data,
        });
        const payload = await actions[data.action].onMessage(data);

        if (actions[data.action].responseAction) {
          target?.contentWindow?.postMessage(
            {
              ...payload,
              source: 'CASDK',
              id: target.name,
              action: actions[data.action].responseAction,
            },
            '*'
          );
        }
      }
      // for debug only
      // else {
      //   logger.log(
      //     `received un-listened message ${data.action}  for ${target?.name}`,
      //     {
      //       function: 'createListenersEvents',
      //       filename,
      //       data,
      //     }
      //   );
      // }
    }
  };

  const controller = new AbortController();

  refWindow.addEventListener('message', listeners[modalId].listener, {
    signal: controller.signal,
  });
  listeners[modalId].signal = controller;
  return controller;
};

export const removeListenersEvents = () => {
  logger.log(`removes all message listeners`, {
    function: 'sendMsg',
    filename,
  });

  for (const listing of Object.values(listeners)) {
    if (listing?.listener) {
      listing.listener &&
        refWindow.removeEventListener('message', listing.listener);
    }

    if (listing?.signal) {
      listing.signal.abort();
    }
  }

  listeners = {};
};

export const listenToModalRemoveFromDom = (
  modalId: string,
  modalName: string
) => {
  const el = document.getElementById(
    `${environment.iframe.id}${modalId === 'global' ? '' : `-${modalId}`}`
  ) as HTMLIFrameElement;

  if (!el) {
    setTimeout(() => listenToModalRemoveFromDom(modalId, modalName), 100);
  } else {
    const obs = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.removedNodes.length > 0) {
          const nodes = Array.from(mutation.removedNodes);
          const isRemoved =
            nodes.indexOf(el) > -1 ||
            nodes.some((parent) => parent.contains(el));

          if (isRemoved) {
            logger.log(`modal ${modalName} removed from DOM`, {
              function: 'listenToModalRemoveFromDom',
              filename,
              pathname: parent.location.pathname,
            });
            parent.postMessage(
              {
                action: 'CLOSE_MODAL',
                source: 'CAMODAL',
                id: modalName,
              },
              '*'
            );
            obs.disconnect();
          }
        }
      });
    });

    obs.observe(document.body, {
      subtree: true,
      childList: true,
    });
  }
};

let testingData: {
  listeners: typeof listeners;
};

if (process.env.NODE_ENV === 'test') {
  testingData = {
    get listeners() {
      return listeners;
    },
    set listeners(l) {
      listeners = l;
    },
  };
}

export { testingData };
