import { logger } from '../../logger';
import { modalDispose } from '../../manager';

const filename = 'dispose.ts';

export const dispose = () => {
  logger.log(`disposing data`, {
    function: 'dispose',
    filename,
  });
  modalDispose();
};
