import { Logger } from '@chargeafter/logger';

import { refWindow } from './utils/windowRef';

import { environment } from '../environments/environment';

const logger = new Logger({
  logsUrl: environment.logsUrl,
  appName: 'SDK',
  production: environment.production,
  origin: refWindow.location?.origin,
});

export { logger };
