import { FeatureFlags } from '@chargeafter/feature-flag';

let localFeatureFlags: FeatureFlags;

const setFeatureFlags = (flags: FeatureFlags) => {
  localFeatureFlags = flags;
};

const getFeatureFlags = () => {
  return localFeatureFlags;
};

export { setFeatureFlags, getFeatureFlags };
