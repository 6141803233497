import { UpdatePromotionsPayload } from '@chargeafter/types-session';
import { WidgetData } from '@chargeafter/types-widgets';
import { asyncDebounce } from '@chargeafter/utils';

import { logger } from '../../logger';
import { refDocument } from '../../utils/windowRef';
import {
  validateWidgetData,
  isWidgetValid,
} from '../../validations/validations';
import { initWidgets } from '../initWidgets/initWidgets';
import { printInitError } from '../printInitError/printInitError';
import { store } from '../store';

const filename = 'update.ts';
const UPDATE_DEBOUNCE_TIME = 500;
const debouncedUpdate = asyncDebounce(
  async (
    items?: UpdatePromotionsPayload,
    language = store.config?.preferences?.language,
    currency?: string
  ) => {
    logger.log(`updating widgets`, {
      function: 'update',
      filename,
      items,
    });

    let widgetsData: WidgetData[];

    if (items?.length) {
      widgetsData = items.reduce((acc, curr) => {
        const sku = curr.sku;
        const price = curr.price;
        let widget =
          store.widgets?.[sku]?.widget ||
          (refDocument.querySelector<HTMLElement>(
            `[data-widget-item-sku=${JSON.stringify(sku)}]`
          )?.dataset as WidgetData);

        if (!widget) return acc;

        widget.widgetItemPrice = price;
        widget = validateWidgetData(widget);

        if (isWidgetValid(widget)) {
          acc.push(widget);
          store.widgets[sku] = {
            ...(store.widgets[sku] || {}),
            widget,
          };
        }

        return acc;
      }, []);
    }

    if (!widgetsData?.length && items?.length) {
      logger.error('the items provided are invalid', {
        function: 'update',
        filename,
      });
    } else {
      await initWidgets(widgetsData, language, currency);
    }
  },
  UPDATE_DEBOUNCE_TIME
);

export const update = async (
  items: UpdatePromotionsPayload = [],
  language = store.config?.preferences?.language,
  currency?: string
) => {
  if (!store.isInitialized) return printInitError('update', 'update.ts');

  for (const item of items) {
    if (item.sku) {
      store.itemsToUpdate.set(item.sku, item);
    }
  }

  await debouncedUpdate(
    Array.from(store.itemsToUpdate.values()),
    language,
    currency
  );

  store.itemsToUpdate.clear();
};
