import { WidgetData } from '@chargeafter/types-widgets';

import { logger } from '../../logger';
import { browserSessionId } from '../../utils/browserSessionId';
import { refDocument, refWindow } from '../../utils/windowRef';
import {
  fetchWidgets,
  getContainersDataSet,
  handleCss,
  handlePromotionsResponse,
} from '../actions/sdk.actions';
import { store } from '../store';

const filename = 'initWidgets';
const href = refWindow.location.href;
const widgetInitLogData = { filename, href, function: 'initWidgets' };

export const initWidgets = async (
  widgetsData?: WidgetData[],
  language?: string,
  currency?: string
) => {
  const isFullyInit = !widgetsData;
  const startTime = Date.now();

  let widgetsPayload: WidgetData[] = widgetsData;

  if (!widgetsData) {
    const { promotionRequestPayload: payload, widgets: containersWidgets } =
      getContainersDataSet();

    widgetsPayload = payload;
    store.widgets = containersWidgets;
  }

  if (!widgetsPayload || widgetsPayload.length === 0) {
    return;
  }

  logger.log(`initializing widgets`, {
    function: 'initWidgets',
    ...widgetInitLogData,
    widgetsData,
  });

  const { apiKey, storeId, currency: configCurrency } = store.config;

  const response = await fetchWidgets({
    widgets: widgetsPayload,
    language,
    apiKey,
    storeId,
    currency: currency || configCurrency,
    sendBrowserSessionStartedEvent:
      browserSessionId.sendBrowserSessionStartedEvent,
  });

  browserSessionId.sendBrowserSessionStartedEvent = false;

  if (response) {
    const {
      widgets: returnedWidgets = {},
      basePromotionModalData: promotionsBaseData = {},
    } = handlePromotionsResponse(store.widgets, response);

    Object.values(returnedWidgets).forEach((widget) => {
      refDocument.querySelectorAll(widget.selector).forEach((el) => {
        el.innerHTML = widget.promotion.el;

        if (!widget.promotion.el && widget.promotion.errorMessage) {
          logger.error(
            widget.promotion.errorMessage.replace(
              '{profile}',
              widget.widget.widgetProfileId
            ),
            {
              ...widgetInitLogData,
              printOriginalToConsole: true,
              isConsoleOnly: true,
              widget: widget.widget,
            }
          );
        }
      });
    });

    response.cssData && handleCss(response.cssData, isFullyInit);

    store.basePromotionModalData =
      promotionsBaseData as typeof store.basePromotionModalData;

    store.config.merchantId = store.basePromotionModalData.merchant?.id;

    const widgetsInitTime = Date.now() - startTime;

    logger.log(`widgets initialized within ${widgetsInitTime}ms`, {
      ...widgetInitLogData,
      widgets: store.widgets,
      widgetsInitTime,
    });
  }
};
