import stringify from 'json-stringify-safe';

import { Log, LogSeverity, LoggerConfig, LogLevel } from './logger.interface';

const MAX_BUFFER_LENGTH = 100;
const ON_ERROR_TIMEOUT = 1000;
const MAX_SEND_RETRY = 3;
const DEBOUNCE_TIMEOUT = 3000;
const MAX_BOYD_SIZE = 1024 * 128;

const logs: unknown[] = [];
let sendTimer: NodeJS.Timeout,
  isSendingInProcess = false,
  currentRetryCount = 0;

const getLogsBodyToSend = (
  currLogs: unknown[]
): {
  body: string;
  currLogs: unknown[];
} => {
  const body = stringify(currLogs);

  const bodySize = new Blob([body]).size / 1024;

  if (MAX_BOYD_SIZE < bodySize) {
    const half = Math.floor(currLogs.length / 2);

    logs.unshift(...currLogs.splice(half, currLogs.length));
    return getLogsBodyToSend(currLogs);
  }

  return {
    body,
    currLogs,
  };
};

const debounce = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (...args: any[]) => void,
  timeout = DEBOUNCE_TIMEOUT
) => {
  return (...args: unknown[]) => {
    if (sendTimer) clearTimeout(sendTimer);

    sendTimer = setTimeout(() => {
      func.apply(this, args);
    }, timeout) as unknown as NodeJS.Timeout;
  };
};

const sendLogsDebounce = debounce((config: LoggerConfig) => {
  sendLogsToServer(config);
});

export const getLogs = () => getLogsBodyToSend(logs.slice());

export const sendLogsToServer = (config: LoggerConfig, isKeepalive = false) => {
  if (!logs.length || currentRetryCount > MAX_SEND_RETRY || !config.logsUrl) {
    return;
  }

  if (isSendingInProcess) {
    return sendLogsDebounce(config);
  }

  isSendingInProcess = true;
  const { body, currLogs } = getLogsBodyToSend(
    logs.splice(0, MAX_BUFFER_LENGTH)
  );

  fetch(config.logsUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
    ...(isKeepalive && {
      keepalive: true,
    }),
  })
    .then((resp) => {
      if (!resp.ok) {
        throw new Error(resp.statusText);
      }

      currentRetryCount = 0;
      isSendingInProcess = false;

      if (logs.length > MAX_BUFFER_LENGTH) {
        sendLogsDebounce(config);
      }
    })
    .catch((error) => {
      isSendingInProcess = false;

      if (currentRetryCount === 0) {
        handleServerLogs(config, LogLevel.ERROR, [
          'Failed to send logs to server',
          {
            error: error.message,
            timestamp: Date.now(),
            function: 'sendLogsToServer',
            filename: 'logger.sender.ts',
          },
        ]);
      }

      const debounceLogsOnError = debounce((config: LoggerConfig) => {
        sendLogsToServer(config);
      }, ON_ERROR_TIMEOUT * 2 * (currentRetryCount === 0 ? 1 : currentRetryCount));

      if (currentRetryCount <= MAX_SEND_RETRY) {
        logs.unshift(...currLogs);
        debounceLogsOnError(config);
      }

      ++currentRetryCount;
    });
};

export const handleServerLogs = (
  config: LoggerConfig,
  logLevel: LogLevel,
  log: Required<Log>['server']
) => {
  const [messageText, logData] = log;

  const correlationId = `frontend${
    config.browserSessionId ? `_${config.browserSessionId}` : ''
  }${config.correlationId ? `_${config.correlationId}` : ''}${
    config.linkId ? `_${config.linkId}` : ''
  }${config.sessionId ? `_${config.sessionId}` : ''}`;

  logs.push({
    severity: LogSeverity[logLevel],
    timestamp: logData.timestamp,
    messageText,
    data: logData,
    correlationId,
  });

  if (logs.length < MAX_BUFFER_LENGTH) {
    sendLogsDebounce(config);
  } else {
    if (sendTimer) clearTimeout(sendTimer);

    sendLogsToServer(config);
  }
};
